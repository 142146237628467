export default class ListEnum {
    //GLOBAL
    static LIST_BLOCK = '[data-element="wpet-block-list"]'
    static LIST_SIDEBAR = '[data-element="wpet-block-list-sidebar"]'
    static LIST_PAGINATION = '[data-element="wpet-pagination"]'
    static LIST_CUSTOM_ORDERS_WRAPPER = '[data-wpet-wrapper="orders"]'

    //ENGINE
    static LIST_ENGINE_WRAPPER = '[data-wpet-wrapper="search-engine"]'
    static LIST_ENGINE_OVERLAY_CONTENT = '[data-element="wpet-list-overlay-content"]'
    static LIST_ENGINE_MARKETPLACE_WIDGET = '[data-wpet-type="marketplace"]'
    static LIST_ENGINE_FILTERS_COUNT = '[data-element="wpet-block-list-filters-count"]'
    static LIST_ENGINE_RESULTS_COUNT = '[data-element="wpet-block-list-results-count"]'
    static LIST_ENGINE_RESET_BUTTON = '[data-element="wpet-block-list-reset-button"]'

    //ENGINE MORE
    static LIST_ENGINE_MORE_TRIGGER = '[data-trigger="wpet-engine-more-button"]'
    static LIST_ENGINE_MORE_LIST = '[data-element="wpet-engine-more-list"]'
    static LIST_ENGINE_MORE_LIST_ITEM = '[data-element="wpet-engine-more-list-item"]'

    //MAP
    static LIST_MAP = '[data-element="wpet-list-map"]'
    static LIST_MAP_WRAPPER = `${ListEnum.LIST_MAP} [data-iris-map][data-wpet-map]`
    static LIST_MAP_BOTTOMBAR = '[data-element="wpet-list-map-bottombar"]'
    static LIST_MAP_TRIGGER_SHOW = '[data-trigger="wpet-list-map-show"]'
    static LIST_MAP_TRIGGER_HIDE = '[data-trigger="wpet-list-map-hide"]'
    static LIST_MAP_TRIGGER_FULL = '[data-trigger="wpet-list-map-full"]'
    static LIST_MAP_MOBILE_TRIGGER = '[data-trigger="wpet-list-map-button"]'
    static LIST_MAP_MOBILE_CLOSE = '[data-trigger="wpet-list-map-close"]'
    static LIST_MAP_SEARCH_ON_MOVE = '[data-trigger="wpet-list-map-search-on-move"]'
    static LIST_MAP_ACTIVE_MODE = '[data-wpet-mode]'
    static LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME = 'data-wpet-mode'
    static LIST_MAP_ELEMENT_NAME = 'data-iris-map'

    //OFFERS
    static LIST_OFFER = '[data-wpet-wrapper="offer"]'
    static LIST_OFFERS = '[data-wpet-wrapper="offers"]'
    static LIST_NO_BOOKING_MESSAGE = '[data-id="wpet-no-booking-message"]'
    static LIST_NO_RESULT_MESSAGE = '[data-id="wpet-no-result-message"]'

    //SHARE
    static LIST_SHARE_COPY_LINK = '[data-attr-wpet-share-copy-button]'
    static LIST_SHARE_COPY_LINK_SUCCESS = '[data-element="wpet-share-copy-success"]'
    static LIST_SHARE_PDF = '[data-attr-wpet-export-pdf]'

    //INFOBOX
    static LIST_INFOBOX_CLOSE = '[data-trigger="wpet-infobox-mobile-close"]'
}
