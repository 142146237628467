import ListEnum from '../enumerators/list.enum'

export default class ListService {
    constructor() {
        //Liste SIT
        if ($(ListEnum.LIST_BLOCK).length > 0) {
            import(
                '../components/list/map.component' /* webpackChunkName: "scripts/map.component" */
            ).then(({ default: MapComponent }) => {
                new MapComponent()
            })

            import(
                '../components/list/engine.component' /* webpackChunkName: "scripts/engine.component" */
            ).then(({ default: EngineComponent }) => {
                new EngineComponent()
            })

            import(
                '../components/list/offers.component' /* webpackChunkName: "scripts/offers.component" */
            ).then(({ default: OffersComponent }) => {
                new OffersComponent()
            })

            import(
                '../components/list/share-url.component' /* webpackChunkName: "scripts/share-url.component" */
            ).then(({ default: ShareUrlComponent }) => {
                new ShareUrlComponent()
            })

            import(
                '../components/list/share-pdf.component' /* webpackChunkName: "scripts/share-pdf.component" */
            ).then(({ default: SharePdfComponent }) => {
                new SharePdfComponent()
            })
        }
    }
}
