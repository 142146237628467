import ListEnum from '../../enumerators/list.enum'

export default class ShareUrlComponent {
    constructor() {
        ShareUrlComponent.copyLinkSuccess()
    }

    static copyLinkSuccess() {
        $(ListEnum.LIST_SHARE_COPY_LINK).on('click', (e) => {
            e.preventDefault()
            $(ListEnum.LIST_SHARE_COPY_LINK_SUCCESS).show()

            setTimeout(() => {
                $(ListEnum.LIST_SHARE_COPY_LINK_SUCCESS).hide()
            }, 2000)
        })
    }
}
