import CardEnum from '../enumerators/card.enum'

export default class CardService {
    constructor() {
        if ($(CardEnum.CARD_SHOW_TRACE).length > 0) {
            import(
                '../components/card/show-trace.component' /* webpackChunkName: "scripts/show-trace.component" */
            ).then(({ default: ShowTraceComponent }) => {
                new ShowTraceComponent()
            })
        }
    }
}
