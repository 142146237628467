//Enumerators
import CardEnum from '../enumerators/card.enum'
import InteractiveMapEnum from '../enumerators/interactive-map.enum'
import ListEnum from '../enumerators/list.enum'

import ShareComponent from '@iris.interactive/handcook/public/scripts/components/share/share.component' // eslint-disable-line import/no-extraneous-dependencies
//Components
import EngineComponent from '../components/list/engine.component'
import MapComponent from '../components/list/map.component'
import OffersComponent from '../components/list/offers.component'
import ShareUrlComponent from '../components/list/share-url.component'

//Supports
import wrapSelect from '@iris.interactive/handcook/public/scripts/support/wrap-select.support' // eslint-disable-line import/no-extraneous-dependencies

export default class AjaxService {
    constructor() {
        AjaxService.initializeListTrigger()
        AjaxService.initializeInteractiveMapTrigger()
    }

    static initializeListTrigger() {
        $(document).on('wp-etourisme-v3/list/update', (target, query) => {
            //On page change or search
            if (
                query.action === 'pagination' ||
                query.action === 'search' ||
                query.action === 'refreshCache'
            ) {
                //Global
                wrapSelect()

                //Offers
                if (query.action !== 'refreshCache') {
                    OffersComponent.backToTop()
                }
                OffersComponent.noResultsMessage(query.data.results)
                OffersComponent.noBookingOffersMessage()

                //Share
                ShareUrlComponent.copyLinkSuccess()
                ShareComponent.manageDisplayOfShareButtons()
                ShareComponent.sharePage()
                ShareComponent.shareOnWhatsappWeb()
                ShareComponent.shareOnMessengerWeb()

                /* eslint-disable */
                if (query.action !== 'refreshCache') {
                    //Lazyload
                    import(
                        '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component' /* webpackChunkName: "scripts/hc_lazyload" */
                    ).then(({ HcLazyload }) => {
                        HcLazyload.update()
                    })

                    //Slider
                    import(
                        '@iris.interactive/handcook/public/scripts/components/slider/slider.component' /* webpackChunkName: "scripts/hc_slider" */
                    ).then(({ default: hc_slider }) => {
                        hc_slider()
                    })

                    //Card
                    if ($(CardEnum.CARD_SHOW_TRACE).length > 0) {
                        import(
                            '../components/card/show-trace.component' /* webpackChunkName: "scripts/show-trace.component" */
                        ).then(({ default: ShowTraceComponent }) => {
                            new ShowTraceComponent()
                        })
                    }
                }
                /* eslint-enable */
            }

            //On search action
            if (query.action === 'search') {
                //Engine
                EngineComponent.moreValuesInit()
                EngineComponent.moreValuesShow()
                EngineComponent.asideEngineMobile()
                EngineComponent.responsiveComportment()

                /* eslint-disable */
                //Collapse
                import(
                    '@iris.interactive/handcook/public/scripts/components/collapse/collapse.component' /* webpackChunkName: "scripts/hc_collapse" */
                ).then(({ default: hc_collapse }) => {
                    hc_collapse()
                })
                /* eslint-enable */
            }

            // On map update
            if (query.action === 'markers') {
                //Map
                MapComponent.infoboxMobile(ListEnum.LIST_MAP_WRAPPER, ListEnum.LIST_MAP_BOTTOMBAR)
            }

            //On filters update
            if (typeof query.data.active !== 'undefined') {
                EngineComponent.updateFiltersCount(query.data.active)
            }

            //On results update
            if (typeof query.data.results !== 'undefined') {
                EngineComponent.updateResultsCount(query.data.results)
            }
        })
    }

    static initializeInteractiveMapTrigger() {
        // Disable click on search button on mobile on init
        $('.wpet-block-interactive-map')
            .find(ListEnum.LIST_ENGINE_RESULTS_COUNT)
            .addClass('disable')

        $(document).on('wp-etourisme-v3/interactive-map/update', (target, query) => {
            if (query.action === 'search') {
                //Global
                wrapSelect()

                if (query.action !== 'refreshCache') {
                    $('html, body').animate(
                        { scrollTop: $(ListEnum.LIST_BLOCK).offset().top - 120 },
                        300,
                    )
                }

                //Engine
                EngineComponent.moreValuesInit()
                EngineComponent.moreValuesShow()
                EngineComponent.asideEngineMobile()
                EngineComponent.responsiveComportment()

                // Able to click on search button on mobile
                if (query.data.results === 0) {
                    $('.wpet-block-interactive-map')
                        .find(ListEnum.LIST_ENGINE_RESULTS_COUNT)
                        .addClass('disable')
                } else {
                    $('.wpet-block-interactive-map')
                        .find(ListEnum.LIST_ENGINE_RESULTS_COUNT)
                        .removeClass('disable')
                }

                /* eslint-disable */
                //Collapse
                import(
                    '@iris.interactive/handcook/public/scripts/components/collapse/collapse.component' /* webpackChunkName: "scripts/hc_collapse" */
                ).then(({ default: hc_collapse }) => {
                    hc_collapse()
                })
                /* eslint-enable */

                // Infobox mobile
                MapComponent.infoboxMobile(
                    InteractiveMapEnum.INTERACTIVE_MAP_WRAPPER,
                    InteractiveMapEnum.INTERACTIVE_MAP_INFOBOX_MOBILE,
                )
            }

            //On filters update
            if (typeof query.data.active !== 'undefined' && query.data.active === 0) {
                $(ListEnum.LIST_ENGINE_RESET_BUTTON).hide()
            } else if (typeof query.data.results !== 'undefined') {
                $(ListEnum.LIST_ENGINE_RESET_BUTTON).show()
            }

            //On results update
            if (typeof query.data.results !== 'undefined') {
                EngineComponent.updateResultsCount(query.data.results)
            }
        })
    }
}
