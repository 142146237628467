import ListEnum from '../../enumerators/list.enum'

export default class MapComponent {
    constructor() {
        MapComponent.showMap()
        MapComponent.showMapFull()
        MapComponent.hideMap()
        MapComponent.showMapMobile()
        MapComponent.hideMapMobile()
        MapComponent.searchOnMoveClick()
        MapComponent.showGpx()
    }

    static createMap() {
        if ($(ListEnum.LIST_MAP_WRAPPER).length === 0) {
            $('[data-wpet-map]').attr('data-iris-map', '')
        }
    }

    static showMap() {
        $(ListEnum.LIST_MAP_TRIGGER_SHOW).on('click', () => {
            MapComponent.createMap()
            if (
                typeof window.IrisMap !== 'undefined' &&
                typeof window.IrisMap.maps !== 'undefined' &&
                window.IrisMap.maps.length > 0
            ) {
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'mixte',
                )
                $(ListEnum.LIST_BLOCK).addClass('has-map')

                if (
                    $(ListEnum.LIST_MAP_WRAPPER).attr('data-iris-map') === '' &&
                    !$(ListEnum.LIST_MAP_WRAPPER).hasClass('maplibregl-map')
                ) {
                    $(document).trigger('iris-map/map/load')
                }

                $(document).trigger('iris-map/map/resize')
                $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
            } else {
                $(ListEnum.LIST_MAP_WRAPPER).attr(ListEnum.LIST_MAP_ELEMENT_NAME, '')
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'mixte',
                )
                $(ListEnum.LIST_BLOCK).addClass('has-map')

                $(document).trigger('iris-map/map/load')

                $(document).on('iris-map-is-loaded', () => {
                    $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
                })
            }
        })
    }

    static showMapFull() {
        $(ListEnum.LIST_MAP_TRIGGER_FULL).on('click', () => {
            MapComponent.createMap()
            if ($(ListEnum.LIST_BLOCK).hasClass('has-map--full')) {
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'mixte',
                )
            } else {
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'map',
                )
            }

            $(ListEnum.LIST_BLOCK).addClass('has-map')
            $(ListEnum.LIST_BLOCK).toggleClass('has-map--full')

            if (
                typeof window.IrisMap !== 'undefined' &&
                typeof window.IrisMap.maps !== 'undefined' &&
                window.IrisMap.maps.length > 0
            ) {
                if (
                    $(ListEnum.LIST_MAP_WRAPPER).attr('data-iris-map') === '' &&
                    !$(ListEnum.LIST_MAP_WRAPPER).hasClass('maplibregl-map')
                ) {
                    $(document).trigger('iris-map/map/load')
                }

                $(document).trigger('iris-map/map/resize')
                $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
            } else {
                $(ListEnum.LIST_MAP_WRAPPER).attr(ListEnum.LIST_MAP_ELEMENT_NAME, '')
                $(document).trigger('iris-map/map/load')

                $(document).on('iris-map-is-loaded', () => {
                    $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
                })
            }
        })
    }

    static hideMap() {
        $(ListEnum.LIST_MAP_TRIGGER_HIDE).on('click', () => {
            $(ListEnum.LIST_BLOCK).removeClass('has-map')
            $(ListEnum.LIST_BLOCK).removeClass('has-map--full')
            $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                'list',
            )
            $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
        })
    }

    static showMapMobile() {
        $(ListEnum.LIST_MAP_MOBILE_TRIGGER).on('click', () => {
            MapComponent.createMap()
            $(ListEnum.LIST_MAP).toggleClass('show')
            $(ListEnum.LIST_BLOCK).toggleClass('map-open')
            $(ListEnum.LIST_MAP_MOBILE_TRIGGER).toggleClass('twet-hidden')

            if ($(ListEnum.LIST_BLOCK).hasClass('map-open')) {
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'map',
                )
            } else {
                $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                    ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                    'list',
                )
            }

            if (
                typeof window.IrisMap !== 'undefined' &&
                typeof window.IrisMap.maps !== 'undefined' &&
                window.IrisMap.maps.length > 0
            ) {
                $(document).trigger('iris-map/map/resize')
                $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
            } else {
                $(ListEnum.LIST_MAP_WRAPPER).attr(ListEnum.LIST_MAP_ELEMENT_NAME, '')
                $(document).trigger('iris-map/map/load')

                $(document).on('iris-map-is-loaded', () => {
                    $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
                })
            }
        })
    }

    static hideMapMobile() {
        $(ListEnum.LIST_MAP_MOBILE_CLOSE).on('click', () => {
            $(ListEnum.LIST_MAP).removeClass('show')
            $(ListEnum.LIST_BLOCK).removeClass('map-open')
            $(ListEnum.LIST_MAP_MOBILE_TRIGGER).toggleClass('twet-hidden')
            $(ListEnum.LIST_MAP_ACTIVE_MODE).attr(
                ListEnum.LIST_MAP_ACTIVE_MODE_ATTRIBUTE_NAME,
                'list',
            )
            $(ListEnum.LIST_MAP_ACTIVE_MODE).trigger('wp-etourisme-v3/list/mode-map-change')
        })
    }

    static searchOnMoveClick() {
        $(ListEnum.LIST_MAP_SEARCH_ON_MOVE).on('click', function () {
            if ($(this).find('input[type="checkbox"]').is(':checked')) {
                $(this).find('input[type="checkbox"]').prop('checked', false)
                $(ListEnum.LIST_MAP).removeClass('disable-loader')
            } else {
                $(this).find('input[type="checkbox"]').prop('checked', true)
                $(ListEnum.LIST_MAP).addClass('disable-loader')
            }
        })
    }

    static showGpx() {
        /* eslint-disable */
        if (
            typeof IRISCollectionTheme !== 'undefined' &&
            typeof IRISCollectionTheme.data !== 'undefined' &&
            typeof IRISCollectionTheme.data.kml !== '' &&
            IRISCollectionTheme.data.kml !== null
        ) {
            import('../gpx.component' /* webpackChunkName: "scripts/gpx.component" */).then(
                ({ default: GpxComponent }) => {
                    new GpxComponent()
                },
            )
        }
        /* eslint-enable */
    }

    static infoboxMobile(map, infoboxWrapper) {
        if (
            window.matchMedia('(max-width: 650px)').matches &&
            $(map).length > 0 &&
            $(infoboxWrapper).length > 0
        ) {
            //Open trigger
            $(document).off('wp-etourisme-v3/map/markers/open')
            $(document).on('wp-etourisme-v3/map/markers/open', () => {
                setTimeout(() => {
                    const infobox = $(map).find('.iris-card')
                    if (typeof infobox !== 'undefined') {
                        let card = ''
                        infobox.each((index, element) => {
                            card += $(element).prop('outerHTML')
                        })
                        if (typeof card !== 'undefined') {
                            $(infobox).hide()
                            $(infoboxWrapper).html(
                                '<div class="wpet-infobox-mobile"><div data-trigger="wpet-infobox-mobile-close" class="wpet-infobox-mobile__close"></div><div class="wpet-infobox-mobile__wrapper"></div></div>',
                            )
                            $(infoboxWrapper).find('.wpet-infobox-mobile__wrapper').append(card)
                        } else {
                            $(infoboxWrapper).html('')
                        }
                    }
                }, 100)
            })

            //Close trigger
            $(document).on('click', ListEnum.LIST_INFOBOX_CLOSE, () => {
                $(infoboxWrapper).html('')
            })
        }
    }
}
