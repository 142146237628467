import ListEnum from '../../enumerators/list.enum'

export default class OffersComponent {
    constructor() {
        OffersComponent.noBookingOffersMessage()
    }

    static backToTop() {
        $('html, body').animate({ scrollTop: $(ListEnum.LIST_BLOCK).offset().top - 120 }, 300)
    }

    static noBookingOffersMessage() {
        if (
            $(ListEnum.LIST_ENGINE_MARKETPLACE_WIDGET).length > 0 &&
            $(ListEnum.LIST_ENGINE_MARKETPLACE_WIDGET).find('#wpetMarketplaceStartDate').val() !==
                '' &&
            (0 === $(ListEnum.LIST_CUSTOM_ORDERS_WRAPPER).length ||
                ($(ListEnum.LIST_CUSTOM_ORDERS_WRAPPER).length > 0 &&
                    $(ListEnum.LIST_CUSTOM_ORDERS_WRAPPER).find('select').val() === 'none'))
        ) {
            let hasMessage = false
            $(ListEnum.LIST_NO_BOOKING_MESSAGE).remove()
            $.each($(ListEnum.LIST_OFFER), function () {
                if (
                    0 === $(this).find('.wpet-bookable').length &&
                    undefined === $(this).data('wpet-contextual-banner') &&
                    0 === $(this).find('[data-wpet-contextual-block]').length &&
                    0 === $(ListEnum.LIST_NO_BOOKING_MESSAGE).length
                ) {
                    if (hasMessage === false) {
                        $(
                            '<div data-id="wpet-no-booking-message" class="wpet-block-list__offers__message">' +
                                IRISCollectionTheme.translate.noBookingOffer + // eslint-disable-line
                                '</div>',
                        ).insertBefore($(this))
                        hasMessage = true
                    }
                }
            })
        } else if ($(ListEnum.LIST_NO_BOOKING_MESSAGE).length > 0) {
            $(ListEnum.LIST_NO_BOOKING_MESSAGE).remove()
        }
    }

    static noResultsMessage(resultsCount) {
        if (typeof resultsCount !== 'undefined' && resultsCount === 0) {
            $(ListEnum.LIST_OFFERS).html(
                '<div data-id="wpet-no-result-message" class="wpet-block-list__offers__message">' +
                    IRISCollectionTheme.translate.noResult + // eslint-disable-line
                    '</div>',
            )
        } else if ($(ListEnum.LIST_NO_RESULT_MESSAGE).length > 0) {
            $(ListEnum.LIST_NO_RESULT_MESSAGE).remove()
        }
    }
}
