import ListEnum from '../../enumerators/list.enum'

export default class EngineComponent {
    constructor() {
        EngineComponent.moreValuesInit()
        EngineComponent.moreValuesShow()
        EngineComponent.asideEngineMobile()
        EngineComponent.responsiveComportment()
    }

    static moreValuesInit() {
        //Init visible values (set max-height for 5 rows)
        $(ListEnum.LIST_ENGINE_MORE_LIST).each(function () {
            let maxHeight = 0
            const items = $(this).find(ListEnum.LIST_ENGINE_MORE_LIST_ITEM)

            if (
                $(ListEnum.LIST_BLOCK).data('mode') === 'hide' &&
                window.matchMedia('(min-width: 651px)').matches
            ) {
                let maxHeightCol1 = 0
                let maxHeightCol2 = 0

                $(items).each(function (index) {
                    if (index < 5) {
                        maxHeightCol1 = maxHeightCol1 + $(this).outerHeight(true)
                    } else {
                        maxHeightCol2 = maxHeightCol2 + $(this).outerHeight(true)
                    }

                    if (index === 9) {
                        return false
                    }
                })

                maxHeight = maxHeightCol1 >= maxHeightCol2 ? maxHeightCol1 : maxHeightCol2
            } else {
                $(items).each(function (index) {
                    maxHeight = maxHeight + $(this).outerHeight(true)
                    if (index === 4) {
                        return false
                    }
                })
            }

            $(this).css({ maxHeight })
        })
    }

    static moreValuesShow() {
        $(ListEnum.LIST_ENGINE_MORE_TRIGGER).on('click', function () {
            $(this).siblings(ListEnum.LIST_ENGINE_MORE_LIST).css({ maxHeight: 'unset' })
            $(this).siblings(ListEnum.LIST_ENGINE_MORE_LIST).removeAttr('data-element')
            $(this).hide()
        })
    }

    static asideEngineMobile() {
        // Prevent Android show / hide keyboard
        if (document.activeElement.tagName !== 'INPUT') {
            // eslint-disable-line
            if (
                $(ListEnum.LIST_BLOCK).data('mode') === 'aside' &&
                window.matchMedia('(max-width: 1000px)').matches
            ) {
                $(ListEnum.LIST_ENGINE_WRAPPER).appendTo(ListEnum.LIST_ENGINE_OVERLAY_CONTENT)
            } else if (
                $(ListEnum.LIST_BLOCK).data('mode') === 'aside' &&
                window.matchMedia('(min-width: 1001px)').matches
            ) {
                $(ListEnum.LIST_ENGINE_WRAPPER).appendTo(ListEnum.LIST_SIDEBAR)
                $('body').removeClass('hc-overlay-lock')
            }
        }
    }

    static responsiveComportment() {
        window.addEventListener('orientationchange', () => EngineComponent.moreValuesInit())
        window.addEventListener('resize', () => EngineComponent.moreValuesInit())
        window.addEventListener('resize', () => EngineComponent.asideEngineMobile())
    }

    static updateFiltersCount(count) {
        if ($(ListEnum.LIST_ENGINE_FILTERS_COUNT).length > 0) {
            if (count === 0) {
                $(ListEnum.LIST_ENGINE_FILTERS_COUNT).hide()
                $(ListEnum.LIST_ENGINE_RESET_BUTTON).hide()
                $(ListEnum.LIST_ENGINE_FILTERS_COUNT).text(count)
            } else {
                $(ListEnum.LIST_ENGINE_FILTERS_COUNT).show()
                $(ListEnum.LIST_ENGINE_RESET_BUTTON).show()
                $(ListEnum.LIST_ENGINE_FILTERS_COUNT).text(count)
            }
        }
    }

    static updateResultsCount(results) {
        if ($(ListEnum.LIST_ENGINE_RESULTS_COUNT).length > 0) {
            if (results === 0) {
                $(ListEnum.LIST_ENGINE_RESULTS_COUNT).text(
                    IRISCollectionEtourisme.translate.searchEngineButtonLabelNoResult,
                ) // eslint-disable-line
            } else if (results === 1) {
                $(ListEnum.LIST_ENGINE_RESULTS_COUNT).text(
                    IRISCollectionEtourisme.translate.searchEngineButtonLabelSingular,
                ) // eslint-disable-line
            } else {
                $(ListEnum.LIST_ENGINE_RESULTS_COUNT).text(
                    IRISCollectionEtourisme.translate.searchEngineButtonLabelPluralStart +
                        ' ' +
                        results +
                        ' ' +
                        IRISCollectionEtourisme.translate.searchEngineButtonLabelPluralEnd,
                ) // eslint-disable-line
            }
        }
    }
}
