export default class DataLayerService {
    constructor() {
        if (IRISCollectionTheme.config.GTMActive) {
            // eslint-disable-line
            import(
                '../actions/data-layer.action' /* webpackChunkName: "scripts/data-layer.action" */
            ).then(({ default: DataLayerAction }) => {
                new DataLayerAction()
            })
        }
    }
}
